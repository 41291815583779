
import { defineComponent, ref } from 'vue';
import { ElConfigProvider } from 'element-plus';
import useEmitter from '@/composables/Emmiter'
import ApiService from '@/services/ApiService';
import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: "filtro-Usuarios",
  components: {
    FilterBase,
    ElConfigProvider
  },

  setup() {
    const dadosUsuariosOptions:any = ref([])
    const codUsuario :any = ref([])
    const form = ref({
      nome: "",
      nivel: ""
    });

    const store = useStore();

    const filter = ref(['']);

    const emitter = useEmitter();

    const closeModal = ref(false);

    const lastEmitIsNull = ref(false);

    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");

    const buscarNivelusuario = [
      {
        text: "Administrador",
        value: -1,
      },
      {
        text: distribuidor == "stellantis" ? "Gestor de checklist" : "Gerência de Pós-venda",
        value: 1,
      },
       {
        text: "Consultor",
        value: 2,
      }, 
      {
        text: "Orçamentista",
        value: 3,
      },
      {
        text: "Controlador de quadro",
        value: 4,
      }, 
      {
        text: "Visualizador de quadro",
        value: 5,
      },
    ]

    const loading: any = ref({
      concessionaria: false
    });
    
    const showGoToBack = ref(false);

    async function emiteMudanca() {
      showGoToBack.value = false;
      await ApiService.valideTokenTime();

      emitter.emit("filtrar-crud-usuarios", {
        nome: filter.value.includes('usuario') ? form.value.nome: "",
        codNivel: filter.value.includes('nivel') ? form.value.nivel : ""
      });
      closeModal.value = !closeModal.value;
    }

    return {
      closeModal,
      loading,
      emiteMudanca,
      lastEmitIsNull,
      showGoToBack,
      dadosUsuariosOptions,
      form,
      codUsuario,
      buscarNivelusuario,
      filter,
    }
  }
});
